<template>
  <div class="container">
    <span class="success-svg">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="48" height="48"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/><path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>
    </span>

    <h1>O seu pagamento foi correctamente processado!</h1>
    <h4>Linha de <a href="mailto:ola@farmaloop.pt">apoio ao cliente</a> sempre disponivel para o ajudar.</h4>
  </div>
</template>

<script>
export default {
  name: "Success"
}
</script>

<style>
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .success-svg {
    color: #047857;
  }
</style>
