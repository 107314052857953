<template>
  <div class="container">
    <span class="error-svg">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="48" height="48"><path d="M0 0h24v24H0z" fill="none"/><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
    </span>

    <h1>Ocorreu um problema ao processar o seu pagamento!</h1>
    <h4> Por favor contacte o nosso <a href="mailto:ola@farmaloop.pt">apoio ao cliente</a></h4>
  </div>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style>
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .error-svg {
    color: #B45309;
  }
</style>
