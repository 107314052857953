<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    />
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
  name: 'Home',
  components: {
    StripeCheckout,
  },
  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sessionId: null
    };
  },
  created() {
    const body = {}
    const params = new URLSearchParams(window.location.search)

    if(!params.has('c')) { window.location = process.env.VUE_APP_HOST }
    
    body.domain = params.get('c')
    if(params.has('debit')) {
      body.debit = 1
    }

    fetch(`${process.env.VUE_APP_API_HOST}/api/v1/checkout_sessions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    .then((resp) => {
      if (resp.ok) return resp.json()
      return resp.json().then((resp) => {
        throw resp
      })
    })
    .then((data) => {
      this.sessionId = data['sessionId']
      this.submit()
    })
    .catch((_error) => {
      this.$router.push("/canceled")
    })
  },
  methods: {
    submit () {
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
}
</script>
